<template>
  <div>
    <audio-player :src="audioFile" />
    <div v-if="noAudio">Geen audio beschikbaar</div>

    <rest-table
        endpoint="tracks"
        :filter="filter"
        :fields="fields"
        :start-at="startAt"
      >

      <template v-slot:cell(artistTitle)="data">
        <router-link :to="{ name: 'track-detail', params: { id: data.item.TrackMetadataID } }">
          <strong>{{ data.item.ProductDetails.Attribution }}</strong> - {{ data.item.ProductDetails.Title }} {{ data.item.ProductDetails.VersionTitle }}
          <b-badge class="float-right" pill variant="secondary" v-if="showPreferredSource && data.item.ProductDetails.PrefSource == 0">
            Verborgen
          </b-badge>
        </router-link>
      </template>
      
      <template v-slot:cell(SingleSaleAllowed)="data">
        <span v-if="data.item.SingleSaleAllowed == '1'" style="font-weight: bold; color: green">V</span>
        <span v-if="data.item.SingleSaleAllowed == '0'" style="font-weight: bold; color: red">X</span>
      </template>

      <template v-slot:cell(AssetFile.IsPresent)="data">
        <span v-if="data.item.AssetFile.IsPresent == '1'" style="font-weight: bold; color: green">V</span>
        <span v-if="data.item.AssetFile.IsPresent == '0'" style="font-weight: bold; color: red">X</span>
      </template>

      <template v-slot:cell(actions)="data">
        <span class="text-nowrap">
          <router-link :to="{ name: 'track-detail', params: { albumId: data.item.AlbumID, id: data.item.TrackMetadataID } }">
            <b-icon-pencil-square></b-icon-pencil-square>
          </router-link>

          <b-icon-play-fill 
            v-if="data.item.AssetFile.IsPresent == 1"
            v-on:click="play(data.item)"
            class="ml-2" />

        </span>
      </template>
    </rest-table>
  </div>
</template>

<script>
  import RestTable from '@/components/RestTable.vue'
  import AudioPlayer from '@/components/AudioPlayer.vue'

  export default {
    name: 'TrackList',
    components: {
      RestTable,
      AudioPlayer,
    },
    props: {
      filter: Object,
      startAt: Number,
      showPreferredSource: Boolean,
    },
    data () {
      return {
        noAudio: false,
        audioFile: '',
        fields: [
          {
            key: 'idx',
            label: '#'
          },
          {
            key: 'ProductDetails.ReportingCode',
            label: 'Boep ID',
            sortable: true,
          },
          {
            key: 'ProductDetails.ISRC',
            label: 'ISRC',
            sortable: true,
          },
          {
            key: 'artistTitle',
            label: 'Artiest en Titel',
            sortable: true,
          },
          {
            key: 'Duration',
            label: 'Duration',
            sortable: true,
          },
          {
            key: 'DeliveredCount',
            label: 'DC',
            headerTitle: 'Aantal Uitgeleverde Albums',
            sortable: true,
          },
          {
            key: 'SingleSaleAllowed',
            label: 'LV',
            headerTitle: 'Losse verkoop',
            sortable: true,
          },
          {
            key: 'AssetFile.IsPresent',
            label: 'A',
            headerTitle: 'Audio',
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Acties',
            class: 'link-icons',
          },
        ]
      }
    },
    methods: {
      play (data) {
        if (data.AssetFile.IsPresent) {
          this.noAudio = false
          this.audioFile = 'https://assets.the-source.eu/clip/' + data.ProductDetails.ReportingCode
        } else {
          this.audioFile = ''
          this.noAudio = true
        }

      }
    }
  }
</script>