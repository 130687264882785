var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("audio-player", { attrs: { src: _vm.audioFile } }),
      _vm.noAudio ? _c("div", [_vm._v("Geen audio beschikbaar")]) : _vm._e(),
      _c("rest-table", {
        attrs: {
          endpoint: "tracks",
          filter: _vm.filter,
          fields: _vm.fields,
          "start-at": _vm.startAt
        },
        scopedSlots: _vm._u([
          {
            key: "cell(artistTitle)",
            fn: function(data) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "track-detail",
                        params: { id: data.item.TrackMetadataID }
                      }
                    }
                  },
                  [
                    _c("strong", [
                      _vm._v(_vm._s(data.item.ProductDetails.Attribution))
                    ]),
                    _vm._v(
                      " - " +
                        _vm._s(data.item.ProductDetails.Title) +
                        " " +
                        _vm._s(data.item.ProductDetails.VersionTitle) +
                        " "
                    ),
                    _vm.showPreferredSource &&
                    data.item.ProductDetails.PrefSource == 0
                      ? _c(
                          "b-badge",
                          {
                            staticClass: "float-right",
                            attrs: { pill: "", variant: "secondary" }
                          },
                          [_vm._v(" Verborgen ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "cell(SingleSaleAllowed)",
            fn: function(data) {
              return [
                data.item.SingleSaleAllowed == "1"
                  ? _c(
                      "span",
                      {
                        staticStyle: { "font-weight": "bold", color: "green" }
                      },
                      [_vm._v("V")]
                    )
                  : _vm._e(),
                data.item.SingleSaleAllowed == "0"
                  ? _c(
                      "span",
                      { staticStyle: { "font-weight": "bold", color: "red" } },
                      [_vm._v("X")]
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "cell(AssetFile.IsPresent)",
            fn: function(data) {
              return [
                data.item.AssetFile.IsPresent == "1"
                  ? _c(
                      "span",
                      {
                        staticStyle: { "font-weight": "bold", color: "green" }
                      },
                      [_vm._v("V")]
                    )
                  : _vm._e(),
                data.item.AssetFile.IsPresent == "0"
                  ? _c(
                      "span",
                      { staticStyle: { "font-weight": "bold", color: "red" } },
                      [_vm._v("X")]
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "cell(actions)",
            fn: function(data) {
              return [
                _c(
                  "span",
                  { staticClass: "text-nowrap" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "track-detail",
                            params: {
                              albumId: data.item.AlbumID,
                              id: data.item.TrackMetadataID
                            }
                          }
                        }
                      },
                      [_c("b-icon-pencil-square")],
                      1
                    ),
                    data.item.AssetFile.IsPresent == 1
                      ? _c("b-icon-play-fill", {
                          staticClass: "ml-2",
                          on: {
                            click: function($event) {
                              return _vm.play(data.item)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }